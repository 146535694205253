// export const URL = "https://app.zilter.io";
// export const URL = 'https://advisor.zoomabroad.com'

export const URL = "https://crm.newcastleedugroup.com";
export const WEB_URL = "https://www.newcastleedugroup.com";

export const HUBSPOT = "https://app.hubspot.com";
export const HUBSPOTAPI = "https://api.hubapi.com";
export const HUBURL = `https://cors-anywhere.herokuapp.com/${HUBSPOTAPI}/`;
export const HUBSPOT_OAUTH_REQUEST = {
    "grant_type": "authorization_code",
    "client_id": "c62bc004-574a-4554-acb7-ef4ad2a76623",
    "client_secret": "620739b9-7224-4972-8dee-62304c0a7f6f",
    "redirect_uri": `${URL}/integration/connect?app=hubspot`,
    "code": ""
}

// export const APISAAS_DOMAIN = "https://uatapi.zilter.io/";


export const APISAAS_DOMAIN = "https://api.newcastleedugroup.com/";
// export const APISAAS_DOMAIN = "http://127.0.0.1:8000/"

export const ASSETS_DOMAIN = "https://betaapi.zoomabroad.com";
export const CLOUDFROND_ASSET_URL = 'https://assets.zoomabroad.com';
export const CLOUDFROND_ASSETS3_URL = 'https://zoomabroad-assets.s3.amazonaws.com';

export const API_DOMAIN = "https://blueapi.zoomabroad.com";
// export const API_DOMAIN = "http://127.0.0.1:8000/";

export const ORG_ID = 'c6294857-e6a1-4472-bead-2fd90593b61a';

// export const ORG_ID = 'efdf7895-8dac-471b-b9d9-325920bf0847';


// export const BRAND_IMAGE_WHITE_LINK = '/img/CTC-global-white.png';
export const BRAND_IMAGE_WHITE_LINK = '/img/IESAW-white.png';