import mirrorkey from 'mirrorkey';

export default mirrorkey([
    'GET_ALL_STUDENTS_REQUEST',
    'GET_ALL_STUDENTS_SUCCESS',
    'GET_ALL_STUDENTS_FAIL',
    'GET_ALL_APPLICATION_REQUEST',
    'GET_ALL_APPLICATION_SUCCESS',
    'GET_ALL_APPLICATION_FAIL',
    'GET_STUDENT_REQUEST',
    'GET_STUDENT_SUCCESS',
    'GET_STUDENT_FAIL',
    
    'GET_STUDENT_APPLICATION_INTAKE_REQUEST',
    'GET_STUDENT_APPLICATION_INTAKE_SUCCESS',
    'GET_STUDENT_APPLICATION_INTAKE_FAIL',

    'GET_COURSE_REQUEST',
    'GET_COURSE_SUCCESS',
    'GET_COURSE_FAIL',
    'GET_STUDENT_REPORT_REQUEST',
    'GET_STUDENT_REPORT_SUCCESS',
    'GET_STUDENT_REPORT_FAIL',
    'SAAS_UPLOAD_REQUEST_FAIL',
    'SAAS_UPLOAD_REQUEST_SUCCESS',
    'SAAS_UPLOAD_REQUEST',
    'UPDATE_REPORT_REQUEST',
    'UPDATE_REPORT_SUCCESS',
    'UPDATE_REPORT_FAIL',
    'SAAS_INTERVIEW_LINK_REQUEST_FAIL',
    'SAAS_INTERVIEW_LINK_REQUEST_SUCCESS',
    'SAAS_INTERVIEW_LINK_REQUEST',
    'UPDATE_STUDENT_REPORT_REQUEST',
    'UPDATE_STUDENT_REPORT_SUCCESS',
    'UPDATE_STUDENT_REPORT_FAIL',
    'SAAS_OFFER_LETTER_REQUEST_FAIL',
    'SAAS_OFFER_LETTER_REQUEST_SUCCESS',
    'SAAS_OFFER_LETTER_REQUEST',
    'GET_LOG_REQUEST',
    'GET_LOG_FAIL',
    'GET_LOG_SUCCESS',
    'POST_LOG_REQUEST',
    'POST_LOG_FAIL',
    'POST_LOG_SUCCESS',
    'POST_SENDEMAIL_REQUEST',
    'POST_SENDEMAIL_FAIL',
    'POST_SENDEMAIL_SUCCESS',
    'UPDATE_LOGS_REQUEST',
    'UPDATE_LOGS_SUCCESS',
    'UPDATE_LOGS_FAIL',
    'GET_APPLIED_COURSES_REQUEST',
    'GET_APPLIED_COURSES_FAIL',
    'GET_APPLIED_COURSES_SUCCESS',
    'GET_APPLICATION_STATUS_REQUEST',
    'GET_APPLICATION_STATUS_FAIL',
    'GET_APPLICATION_STATUS_SUCCESS',
    'UPDATE_ASSIGNED_REQUEST',
    'UPDATE_ASSIGNED_FAIL',
    'UPDATE_ASSIGNED_SUCCESS',
    'POST_ACADEMIC_REQUEST',
    'POST_ACADEMIC_FAIL',
    'POST_ACADEMIC_SUCCESS',
    'POST_USER_DOCUMENTS_REQUEST',
    'POST_USER_DOCUMENTS_FAIL',
    'POST_USER_DOCUMENTS_SUCCESS',
    'GET_USER_DOCUMENTS_REQUEST',
    'GET_USER_DOCUMENTS_FAIL',
    'GET_USER_DOCUMENTS_SUCCESS',
    'GET_SOP_DOCUMENTS_REQUEST',
    'GET_SOP_DOCUMENTS_FAIL',
    'GET_SOP_DOCUMENTS_SUCCESS',
    'GET_PASSPORT_DOCUMENTS_REQUEST',
    'GET_PASSPORT_DOCUMENTS_FAIL',
    'GET_PASSPORT_DOCUMENTS_SUCCESS',
    'GET_VISA_DOCUMENTS_REQUEST',
    'GET_VISA_DOCUMENTS_FAIL',
    'GET_VISA_DOCUMENTS_SUCCESS',
    'GET_LOR_DOCUMENTS_REQUEST',
    'GET_LOR_DOCUMENTS_FAIL',
    'GET_LOR_DOCUMENTS_SUCCESS',
    'GET_OTHER_DOCUMENTS_REQUEST',
    'GET_OTHER_DOCUMENTS_FAIL',
    'GET_OTHER_DOCUMENTS_SUCCESS',
    'GET_ZA_STUDENT_REQUEST',
    'GET_ZA_STUDENT_FAIL',
    'GET_ZA_STUDENT_SUCCESS',
    'POST_EDUCATIONAL_DATA_REQUEST',
    'POST_EDUCATIONAL_DATA_FAIL',
    'POST_EDUCATIONAL_DATA_SUCCESS',
    'POST_PROFICIENCY_DATA_REQUEST',
    'POST_PROFICIENCY_DATA_FAIL',
    'POST_PROFICIENCY_DATA_SUCCESS',
    'GET_EDUCATIONAL_DATA_REQUEST',
    'GET_EDUCATIONAL_DATA_FAIL',
    'GET_EDUCATIONAL_DATA_SUCCESS',
    'GET_PROFICIENCY_DATA_SUCCESS',
    'GET_PROFICIENCY_DATA_REQUEST',
    'GET_PROFICIENCY_DATA_FAIL',
    'GET_EXPERIENCE_DATA_SUCCESS',
    'GET_EXPERIENCE_DATA_REQUEST',
    'GET_EXPERIENCE_DATA_FAIL',
    'POST_EXPERIENCE_DATA_REQUEST',
    'POST_EXPERIENCE_DATA_FAIL',
    'POST_EXPERIENCE_DATA_SUCCESS',
    'GET_USER_DOCUMENTS_REQUEST',
    'GET_USER_DOCUMENTS_FAIL',
    'GET_USER_DOCUMENTS_SUCCESS',
    'UPDATE_STUDENT_REQUEST',
    'UPDATE_STUDENT_FAIL',
    'UPDATE_STUDENT_SUCCESS',
    'DELETE_STUDENT_LOGS_REQUEST',
    'DELETE_STUDENT_LOGS_FAIL',
    'DELETE_STUDENT_LOGS_SUCCESS',
    'STUDENT_VIEW_BACK_BUTTON',
    'STUDENT_LEAD_BACK_BUTTON',
    'STUDENT_SEARCH_BACK_BUTTON',
    'STUDENT_ASSIGNED_BACK_BUTTON',
    'STUDENT_MONTH_BACK_BUTTON',
    'STUDENT_YEAR_BACK_BUTTON',
    'STUDENT_SOURCE_BACK_BUTTON',
    'GET_EXPORT_STUDENTS_REQUEST',
    'GET_EXPORT_STUDENTS_FAIL',
    'GET_EXPORT_STUDENTS_SUCCESS',
    'POST_APPLY_COURSE_REQUEST',
    'POST_APPLY_COURSE_FAIL',
    'POST_APPLY_COURSE_SUCCESS',
    'GET_ZOOMABROAD_COURSES_FAIL',
    'GET_ZOOMABROAD_COURSES_SUCCESS',
    'GET_ZOOMABROAD_COURSES_REQUEST',
    'GET_ZOOMABROAD_UNIVERSITIES_REQUEST',
    'GET_ZOOMABROAD_UNIVERSITIES_FAIL',
    'GET_ZOOMABROAD_UNIVERSITIES_SUCCESS',
    'POST_INTERVIEW_NEW_REQUEST',
    'POST_INTERVIEW_NEW_REQUEST_FAIL',
    'POST_INTERVIEW_NEW_REQUEST_SUCCESS',
    'DELETE_INTERVIEW_REQ',
    'DELETE_INTERVIEW_FAILED',
    'DETELE_INTERVIEW_SUCCESS',
    'POST_BALANCE_FEE_REQUEST',
    'POST_BALANCE_FEE_FAIL',
    'POST_BALANCE_FEE_SUCCESS',

    'GET_BALANCE_FEE_REQUEST',
    'GET_BALANCE_FEE_FAIL',
    'GET_BALANCE_FEE_SUCCESS',

    'GET_CONDITIONAL_OFFER_REQUEST',
    'GET_CONDITIONAL_OFFER_FAIL',
    'GET_CONDITIONAL_OFFER_SUCCESS',

    'GET_UNCONDITIONAL_REQUEST',
    'GET_UNCONDITIONAL_FAIL',
    'GET_UNCONDITIONAL_SUCCESS',

    'GET_INVOICE_REQUEST',
    'GET_INVOICE_FAIL',
    'GET_INVOICE_SUCCESS',

    'GET_ASSESSMENT_REQUEST',
    'GET_ASSESSMENT_FAIL',
    'GET_ASSESSMENT_SUCCESS',

    'GET_ENROLLED_REQUEST',
    'GET_ENROLLED_FAIL',
    'GET_ENROLLED_SUCCESS',

    'GET_LOST_REQUEST',
    'GET_LOST_FAIL',
    'GET_LOST_SUCCESS',

    'GET_APPLIED_REQUEST',
    'GET_APPLIED_FAIL',
    'GET_APPLIED_SUCCESS',

    'GET_FEE_DEPOSIT_REQUEST',
    'GET_FEE_DEPOSIT_FAIL',
    'GET_FEE_DEPOSIT_SUCCESS',

    'GET_APPOINTMENT_REQUEST',
    'GET_APPOINTMENT_FAIL',
    'GET_APPOINTMENT_SUCCESS',

    'GET_GRANTED_REQUEST',
    'GET_GRANTED_FAIL',
    'GET_GRANTED_SUCCESS',

    'GET_REFUSAL_REQUEST',
    'GET_REFUSAL_FAIL',
    'GET_REFUSAL_SUCCESS',

    'GET_CAS_RECEIVED_REQUEST',
    'GET_CAS_RECEIVED_FAIL',
    'GET_CAS_RECEIVED_SUCCESS',

    'GET_CAS_APPLIED_REQUEST',
    'GET_CAS_APPLIED_FAIL',
    'GET_CAS_APPLIED_SUCCESS',

    'STUDENT_START_DATE_BACK_BUTTON',
    
    'STUDENT_END_DATE_BACK_BUTTON',

    'STUDENT_UNIVERSITY_BACK_BUTTON',

    'STUDENT_COURSE_BACK_BUTTON',

    'STUDENT_ADVANCED_FILTER_BACK_BUTTON',

    'STUDENT_APPLICATION_MODEL_OPEN',

    'STUDENT_APPLICATION_STATUS_BACK_BUTTON',

    'GET_APP_REJECTED_REQUEST',
    'GET_APP_REJECTED_FAIL',
    'GET_APP_REJECTED_SUCCESS',
    
    'POST_COUNSELLOR_MEETING_REQUEST',
    'POST_COUNSELLOR_MEETING_FAIL',
    'POST_COUNSELLOR_MEETING_SUCCESS',
    'POST_ZA_COUNSELLOR_MEETING_REQUEST',
    'POST_ZA_COUNSELLOR_MEETING_FAIL',
    'POST_ZA_COUNSELLOR_MEETING_SUCCESS',
    'POST_TASK_ALLOCATION_REQUEST',
    'POST_TASK_ALLOCATION_FAIL',
    'POST_TASK_ALLOCATION_SUCCESS',
    'POST_COUNSELLOR_REQUIRED_DOC_REQUEST',
    'POST_COUNSELLOR_REQUIRED_DOC_SUCCESS',
    'POST_COUNSELLOR_REQUIRED_DOC_FAIL',
    'GET_MEETING_DETAILS_REQUEST',
    'GET_MEETING_DETAILS_SUCCESS',
    'GET_MEETING_DETAILS_FAIL',
    'UPDATE_ZA_ASSIGNED_REQUEST',
    'UPDATE_ZA_ASSIGNED_FAIL',
    'UPDATE_ZA_ASSIGNED_SUCCESS',
    'POST_ZA_TASK_ALLOCATION_REQUEST',
    'POST__ZA_TASK_ALLOCATION_FAIL',
    'POST__ZA_TASK_ALLOCATION_SUCCESS',
    'GET_CHECKLIST_REQUEST',
    'GET_CHECKLIST_FAIL',
    'GET_CHECKLIST_SUCCESS',
    'UPDATE_SUPPORT_STAFF_REQUEST',
    'UPDATE_SUPPORT_STAFF_FAIL',
    'UPDATE_SUPPORT_STAFF_SUCCESS',
    'STUDENT_SUPPORT_STAFF_BACK_BUTTON',
    'GET_FEEDBACK_FORM_REQUEST',
    'GET_FEEDBACK_FORM_FAIL',
    'GET_FEEDBACK_FORM_SUCCESS',
    'GET_TASK_VISA_APPOINTMENT_FAIL',
    'GET_TASK_VISA_APPOINTMENT_SUCCESS',
    'GET_TASK_VISA_APPOINTMENT_REQUEST'

])

