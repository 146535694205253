import React, { Component } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { H5, MenuItem, Switch } from "@blueprintjs/core";
import AppliactionCreate from '../Profile/ApplicationCreate'
import { Suggest } from "@blueprintjs/select";
import { Toast } from '../../../../shared'
import actions from '../../modules/actions'
import { ORG_ID } from '../../../../env'
import moment from 'moment';
import {
    Label
} from "@blueprintjs/core";
import './ConfirmingInterests.scss';

class ConfirmingInterests extends Component {
    constructor(props) {
        super(props)
        this.state = {
            universityName: '',
            courseName: '',
            year: '',
            month: '',
            degree: '',
            id: '',
            errors: {},
            multiple: [1]
        }
    }
    componentDidMount() {
        const { match: { params }, userProfile, student } = this.props;
        this.props.actions.getStudent(params.id);
    }

    static getDerivedStateFromProps(props, state) {

    }

    isvalid = () => {
        const { universityName, courseName } = this.state;
        let errors = {};

        if (universityName == '') {
            errors.universityName = true;
            this.setState({ errors });
            return false;
        }
        if (courseName == '') {
            errors.courseName = true;
            this.setState({ errors });
            return false;
        }


        return true;
    };
    onAdd = () => {
        const { multiple } = this.state
        multiple.push(1)
        this.setState({
            multiple
        })
    }
    onSubmit = () => {
        const { match: { params }, userProfile, student, onCloseModal } = this.props;
        const { universityName, courseName, multiple } = this.state;
        let data = []
        let zadata = []
        let note = []
        for (let i = 0; i < multiple.length; i++) {

            let opportunityData = {}

            opportunityData = {
                "option_course_name": `${this.state[`courseName${i}`]} ${this.state[`degree${i}`]} ${this.state[`month${i}`]} ${this.state[`year${i}`]}`,
                "option_course_description": this.state[`universityName${i}`],
                "selected_course": 0,
                "type": 'confirm_courses',
                "selected_offer": null,
                "student_id": params.id,
                "user_id": userProfile.organization.user_id
            }
            data.push(opportunityData)
            let noteT = {
                "Course_Option": `${this.state[`courseName${i}`]} ${this.state[`degree${i}`]} ${this.state[`universityName${i}`]} ${this.state[`month${i}`]} ${this.state[`year${i}`]}`
            }
            noteT = JSON.stringify(noteT)
            noteT = noteT.replace('{', '')
            noteT = noteT.replace('}', ',')

            note.push(noteT)
            let opportunityZAData = {}
            opportunityZAData = {
                "option_course_name": `${this.state[`courseName${i}`]} ${this.state[`degree${i}`]} ${this.state[`month${i}`]} ${this.state[`year${i}`]}`,
                "option_course_description": this.state[`universityName${i}`],
                "selected_course": 0,
                "type": 'confirm_courses',
                "selected_offer": null,
                'user_id': student ? student.za_id : null,
            }
            zadata.push(opportunityZAData)

        }
        const taskList = {
            'student_id': params.id,
            'user_id': userProfile.organization.user_id,
            'organization_id': userProfile.organization.organization_id,
            'meeting_schedule': false,
            'attending_meeting': false,
            'required_documents': false,
            'visa_appointment': false,
            'opportunity': true,
            'select_offer': false,
            'qualifying': false,

        }
        const ZAtaskList = {
            'user_id': student ? student.za_id : null,
            'meeting_schedule': false,
            'attending_meeting': false,
            'required_documents': false,
            'visa_appointment': false,
            'opportunity': true,
            'select_offer': false,
            'qualifying': false,

        }
        const log = {
            "std": params.id,           ////student_id
            "u_id": userProfile ? userProfile.organization.user_id : null,
            "student_courses_id": courseName ? courseName : null,
            "subject": 'Task assigned course option',
            "type": 'note',
            "note": note.toString(),
            "note_status": userProfile.organization.organization_id == ORG_ID ? 'private' : 'private',
            "student_name": student.first_name,
            "student_email": student.email,
            "organization_id": userProfile ? userProfile.organization.organization_id : null,

        }
        const handlesuccessfull = () => {
            Toast.show({
                message: "Confirming interest task allocation send successfully!",
                timeout: 4000,
                intent: 'success'
            })
            onCloseModal()

        }
        const handleFailure = () => {
            onCloseModal()
            Toast.show({
                message: "Confirming interest task allocation send unsuccessfully!",
                timeout: 4000,
                intent: 'danger'
            })
            onCloseModal()

        }
        const handlesNoteuccessfull = () => {
            const page = 1
            this.props.actions.getLogs(student.id, page)
        }

        this.props.actions.taskAllocation(taskList, () => { }, () => { })
        this.props.actions.counsellorOpportunity(data, handlesuccessfull, handleFailure)
        this.props.actions.createNoteLog(log, handlesNoteuccessfull, () => { });

        if (ZAtaskList.user_id) {
            this.props.actions.ZAtaskAllocation(ZAtaskList, () => { }, () => { })
            this.props.actions.ZAcounsellorOpportunity(zadata, handlesuccessfull, handleFailure)
        }


    }
    onChangeInput = (name, value) => {
        this.setState({
            [name]: value, errors: {}
        })

    }

    render() {
        const { universityName, errors, courseName, multiple } = this.state;
        const { } = this.props
        console.log("fddsfsdfsdfdss", this.state.courseName1)
        return (
            <div className="confirming-interests">
                {multiple.map((input, index) => {
                    return (<div>
                        <p className="options">{index ? `Option: ${index + 1} ` : ''}</p>
                        <Row>
                            <Col>
                                <Form.Label className="title">Degree Type *</Form.Label>
                                <Form.Control
                                    placeholder="Enter degree type"
                                    type="text"
                                    className="form-control text-box"
                                    value={this.state[`degree${index}`]}
                                    onChange={(event) => this.onChangeInput(`degree${index}`, event.target.value)}
                                />
                                {this.state.errors.degree ? <span className="errors">Please Enter University Name</span> : ''}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className="title">Course Name *</Form.Label>
                                <Form.Control
                                    placeholder="Enter course name"
                                    type="text"
                                    className="form-control text-box"
                                    value={this.state[`courseName${index}`]}
                                    onChange={(event) => this.onChangeInput(`courseName${index}`, event.target.value)}
                                />
                                {this.state.errors.courseName ? <span className="errors">Please Enter Course Name</span> : ''}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className="title">University Name *</Form.Label>
                                <Form.Control
                                    placeholder="Enter university name"
                                    type="text"
                                    className="form-control text-box"
                                    value={this.state[`universityName${index}`]}
                                    onChange={(event) => this.onChangeInput(`universityName${index}`, event.target.value)}
                                />
                                {this.state.errors.universityName ? <span className="errors">Please Enter University Name</span> : ''}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className="title">Intake Month *</Form.Label>
                                <Form.Control
                                    placeholder="Enter intake month"
                                    // type="text"
                                    as='select'
                                    className="form-control text-box"
                                    value={this.state[`month${index}`]}
                                    onChange={(event) => this.onChangeInput(`month${index}`, event.target.value)}
                                >
                                    <option value="">Select</option>
                                    <option value="january">January</option>
                                    <option value="february">February</option>
                                    <option value="march">March</option>
                                    <option value="april">April</option>
                                    <option value="may">May</option>
                                    <option value="june">June</option>
                                    <option value="july">July</option>
                                    <option value="august">August</option>
                                    <option value="september">September</option>
                                    <option value="october">October</option>
                                    <option value="november">November</option>
                                    <option value="december">December</option>
                                </Form.Control>

                                {this.state.errors.month ? <span className="errors">Please Enter Intake Month</span> : ''}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className="title">Intake Year *</Form.Label>
                                <Form.Control
                                    placeholder="Enter intake year"
                                    // type="text"
                                    as='select'
                                    className="form-control text-box"
                                    value={this.state[`year${index}`]}
                                    onChange={(event) => this.onChangeInput(`year${index}`, event.target.value)}
                                >
                                    <option value="">Select</option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                    <option value="2026">2026</option>
                                    <option value="2027">2027</option>
                                    <option value="2028">2028</option>
                                    <option value="2029">2029</option>
                                    <option value="2030">2030</option>
                                </Form.Control>
                                {this.state.errors.year ? <span className="errors">Please Enter Intake Year</span> : ''}
                            </Col>
                        </Row>

                    </div>)
                })}

                <Col>
                    <Button onClick={() => this.onAdd()}>Add</Button>
                </Col>
                <Col className="button-save">
                    <Button className="save-offer" onClick={() => this.onSubmit()}>Allocate</Button>
                </Col>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        student: state.students.student,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfirmingInterests));
