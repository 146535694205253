import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Image, Form } from 'react-bootstrap';
import './Profile.scss';

const Profile = (props) => {
    const { student, onMessage, onNote, onTask, members, onChangeInput, assignedValue, myState, 
        openInNewTab, leadStatusValue, leadState, sourceList, onWhatsapp, onChatMessage, supportStaffValue, mySupportState 
        ,openFeedbackModal, userProfile} = props;
    
    if(student){
        // if( leadStatusValue == undefined)
        // {
        //     console.log("{student.lead_status}{student.lead_status}", student.lead_status);
            
        //     leadState[leadStatusValue] = student.lead_status
        //     const st = leadState[leadStatusValue]
        //     console.log("{leadState[leadStatusValue]}", leadState[leadStatusValue]);

        // }
        if( assignedValue == undefined)
        {
            myState['assignedValue'] = student.assigned_id
            console.log("myState[assignedValue]", myState[assignedValue]);
        }
        if( supportStaffValue == undefined)
        {
            myState['supportStaffValue'] = student.support_staff_id
            console.log("{>>>myState[supportStaffValue]", myState[supportStaffValue]);
        }
        let supportStaff = members.Users && members.Users.length ? members.Users : []
    }
// console.log(student,"studentstudentstudent");
const sourceName = (source_id) =>{
    const { sourceList } = props
    if(sourceList && sourceList){
        for(let i=0; i< sourceList.length; i++){
            if(sourceList[i].id == source_id){
                return sourceList[i].source_short_name
            }
        }
    }
}
const referenceName = (refer) => {
    if (refer && refer != 'organic') {
        return refer
    }
    else return ''
}
const alternativeContactEmail = (alternative_email) => {
    if (alternative_email) {
        let altEmail = alternative_email
        let resAltEmail = altEmail.replace("[", "");
        let result = resAltEmail.replace("]", "");
        let altEmailData = result.replace(/'/g, '"')
        let altEmailArray = JSON.parse("[" + altEmailData + "]");
    
    return altEmailArray
}
else{
    return null
}
}
const alternativeContactPhone = (alternative_phone_number) =>{
    if (alternative_phone_number) {
        let altPhone = alternative_phone_number
        let resAltPhone = altPhone.replace("[", "");
        let result = resAltPhone.replace("]", "");
        let altPhoneData = result.replace(/'/g, '"')
        let altPhoneArray = JSON.parse("[" + altPhoneData + "]");
        return altPhoneArray
    }
    else{
        return null
    }
}

    return (
        <Row className="profile-section">
            <Col md={3} className="text-center">
                <Image className="avatar" src="https://image.shutterstock.com/image-vector/default-avatar-photo-placeholder-profile-260nw-772402006.jpg" roundedCircle />
            </Col>
            <Col md={9}>
                <div className="name">{student.first_name} {student.last_name}</div>
                <div className="education">{student.course_type}</div>
                <div className="email">{student.email}</div>
                { student && student.alternative_email ?
                    alternativeContactEmail(student.alternative_email).map((email)=>{
                        return(
                            <div className="email">{email}</div>
                        )
                    })
                     : null
                }
                <div className="phone">{student.phone_number}</div>
                { student && student.alternative_phone_number ?
                    alternativeContactPhone(student.alternative_phone_number).map((number)=>{
                        return(
                            <div className="phone">{number}</div>
                        )
                    })
                     : null
                }
                <div>
                    <Button variant="danger" onClick={() => onMessage()}>
                        <FontAwesomeIcon icon={["fab", "telegram-plane"]} />
                        Message
                    </Button>
                    {student.whats_app_status == 'opted-in' ? <Button variant="success" onClick={() => onWhatsapp()}>
                        <FontAwesomeIcon icon={["fab", "whatsapp"]} />
                        Whatsapp
                    </Button> : ''}
                    
                    <Button variant="warning" onClick={() => onNote()}>
                        <FontAwesomeIcon icon={["fas", "edit"]} />
                        Note
                    </Button>
                    <Button variant="info" onClick={() => onTask()}>
                        <FontAwesomeIcon icon={["fas", "calendar-plus"]} />
                        Task
                    </Button>
                    <Button variant="dark" onClick={() => openInNewTab(student.id)}>
                        <FontAwesomeIcon icon={["fas", "external-link-alt"]} />
                        Edit
                    </Button>
                    <Button variant="primary" onClick={() => onChatMessage()}>
                        <FontAwesomeIcon icon={["fas", "calendar-plus"]} />
                        Chat
                    </Button>
                    {/* <Button variant="primary" onClick={() => openFeedbackModal()}>
                        <FontAwesomeIcon icon={["fas", "calendar-plus"]} />
                        Feedback
                    </Button> */}
                    <p>Source : {student.source?`${sourceName(student.source)} ${referenceName(student.reference_by)}`:student.reference_by}</p>
                    
                    <Form.Group >
                            <Form.Label>Assigned:</Form.Label>
                            <Form.Control
                                className="select-options"
                                name={"assignedValue"}
                                as="select"
                                disabled={userProfile && userProfile.is_counselor? true : false }
                                placeholder="Status"
                                value={myState['assignedValue'] ? myState['assignedValue'] : null}
                                onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                            >
                                {
                                    members.Users && members.Users.length ? members.Users.map((user) => {
                                        if(user.first_name===null) return
                                        else 
                                        return (
                                            <option value={user.user_id}>{user.first_name}&nbsp;{user.last_name} </option>
                                        )
                                    })
                                        : ''}
                                {/* <option value="">Select...</option>
                                    <option value="inprogress">Inprogress</option>
                                    <option value="pending">Pending</option>
                                    <option value="completed">Completed</option> */}

                            </Form.Control>
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Support Staff:</Form.Label>
                            <Form.Control
                                className="select-options"
                                name={"supportStaffValue"}
                                as="select"
                                disabled={userProfile && userProfile.is_counselor? true : false }
                                placeholder="Status"
                                value={myState['supportStaffValue'] ? myState['supportStaffValue'] : null}
                                onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                            >
                                {
                                    members.Users && members.Users.length ? members.Users.map((user) => {
                                        if(user.first_name===null) return
                                        else 
                                        return (
                                            <option value={user.user_id}>{user.first_name}&nbsp;{user.last_name} </option>
                                        )
                                    })
                                        : ''}
                                {/* <option value="">Select...</option>
                                    <option value="inprogress">Inprogress</option>
                                    <option value="pending">Pending</option>
                                    <option value="completed">Completed</option> */}

                            </Form.Control>
                        </Form.Group>
                        <Form.Group >
                                <Form.Label>Lead Status : </Form.Label>
                            <Form.Control
                                className="select-options"
                                name={"leadStatusValue"}
                                as="select"
                                placeholder="Status"
                                value={leadStatusValue ? leadStatusValue : null}
                                onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                            >
                                <option value="">Select...</option>
                                    <option value="international">International</option>
                                    <option value="subscriber">Subscriber</option>
                                    <option value="ringing">Ringing</option>
                                    <option value="others">Others</option>
                                    <option value="lead">Lead</option>
                                    <option value="hot_lead">Hot Lead</option>
                                    <option value="customer">Customer</option>
                                    <option value="beyond_intake">Beyond Intake</option>

                            </Form.Control>
                        </Form.Group>
                </div>
            </Col>
        </Row>
    )
}
export default Profile;
