import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import actions from '../modules/actions';
import memberActions from '../../Members/modules/actions'
import channelAction from '../../Channel/modules/action'
import courseActions from '../../Course/modules/action'
import { Toast } from "../../../shared/toast/Toast";
import sourceActions from '../../Source/modules/actions'
import _ from 'lodash';
import StudentViewMarkup from '../components/StudentViewMarkup'
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { WEB_URL, URL } from '../../../env';

class StudentView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: {
                showDocModal: false,
                modalDocUrl: '',
                student_id: '',
                first_name: "",
                last_name: "",
                phone_number: "",
                email: "",
                toEmail: "",
                fromEmail: "",
                experiance: {},
                onShowMessage: false,
                onShowNote: false,
                onShowTask: false,
                onShowStatus: false,
                onShowWhatsapp: false,
                pageIndex: 1,
                status: "",
                applicationValue: "",
                assignedValue: "",
                subject: "",
                courseName: "",
                // editorState:"",
                editState: false,
                editId: "",
                leadStatusValue: "",
                noteStatusValue: "",
                showApplicationCreateModal: false,
                appicationCourseCreate: '',
                applicationCourseType: '',
                applicationCourseUniversity: '',
                applicationIntakeYear: '',
                applicationIntakeMonth: '',
                applicationChannelName: '',
                phoneNumber: '',
                showApplicationStatusModal: false,
                applicationStatusIndex: '',
                applicationModalName: '',
                applicationStatusId: '',
                clearModel: true,
                applicationNumber: '',
                application_intake_month:'',
                applied_courses:'',
                application_intake_year:'',
                showApplicationNumberModal: false,
                showApplicationIntakeModal: false,
                showApplicationcourseModal: false,
                applicationEdit: '',
                onShowChatMessage: false,
                showTaskAllocateModal: false,
                showAllocateModal: false,
                taskIndex: 100,
                taskName: '',
                supportStaffValue:'',
                showFeedbackform:false,
                stateFeedBackForm:{}
            },
            editorState: EditorState.createEmpty(),
            editorNoteState: EditorState.createEmpty(),
            editorWhatsState: EditorState.createEmpty()
        }
    }
    static getDerivedStateFromProps(props, state) {

        if (state.inputs !== props.student) {
            return {
                inputs: props.student,
                toEmail: props.student.email,
                fromEmail: props.userProfile.user.email,
                leadStatusValue: props.student.lead_status,
                phoneNumber: props.student.phone_number,
                supportStaffValue: props.student.support_staff_id,
                assignedValue: props.student.assigned_id,
                stateFeedBackForm:props.getFeedbackForm
            }

        }
    }
    componentDidMount() {
        const { match: { params }, userProfile: { organization } } = this.props;
        const { pageIndex } = this.state
        const page = 1
        if (params && params.id && organization) {
            this.props.actions.getStudent(params.id);
            this.props.actions.getStudentApplicationIntake(params.id);
            this.props.actions.getLogs(params.id, page)
            this.props.actions.getStudentAppliedCourses(params.id)
            this.props.actions.getApplicationStatus(organization.organization_id)
            this.props.memberActions.getMembers(organization.organization_id)
            this.props.actions.getZoomabroadUniversities()
            this.props.channelAction.getOrgChannel(organization.organization_id);
            this.props.actions.getZAStudent(params.id, () => { }, () => { })
            this.props.sourceActions.getOrgSource(organization.organization_id);
            this.props.actions.getProgressCheckList(params.id, () => { }, () => { })
            // this.props.actions.getFeedbackForm()
        this.props.actions.attendingMeeting(params.id, () => { }, () => { })


            


        }
    }

    applyZoomabroad = (id) => {
        var win = window.open(`${WEB_URL}/profile/check/${id}`, '_blank');
        win.focus();
    }

    updateStatus = (id, status) => {

        const { match: { params } } = this.props;
        const data = {
            "status": status
        }
        const handleFailure = (body) => {
            this.setState({
                loginErrorMessage: Toast.show({
                    message: "Status Update Failed Please try again",
                    timeout: 4000,
                    intent: 'danger'
                })
            })
        }
        const handleSuccess = () => {
            const pageIndex = 1
            Toast.show({
                message: "Status updated successfully !!!!",
                timeout: 3000,
                intent: 'success'
            })
            this.props.actions.getLogs(params.id, pageIndex)
        };
        this.props.actions.updateTaskLog(id, data, handleSuccess, handleFailure)

    }

    updatApplictioneStatus = (id, status, universityName, appliedCourses, courseLevel, applicationCurrentStatus) => {

        const { match: { params }, userProfile: { organization, user }, student, applicationStatus } = this.props;
        let ind = 0
        let currentStatus = ''
        if(applicationStatus && applicationStatus){
            ind = applicationStatus.findIndex((x) => x.id == status);
            currentStatus = applicationStatus[ind].key
        }

        let ZAstudentId = student.za_id ? student.za_id : null
        const data = {
            "application_status_id": status,
        }
        const user_activity = {
            'student_id': params.id,
            'activities': currentStatus,
            'organization_id': organization.organization_id,
            'user_id': user.id
        }
     
       
        const handleFailure = (body) => {
            this.setState({
                loginErrorMessage: Toast.show({
                    message: "Status Update Failed Please try again",
                    timeout: 4000,
                    intent: 'danger'
                })
            })
        }
        const handleSuccess = () => {
            const pageIndex = 1
            Toast.show({
                message: "Status updated successfully !!!!",
                timeout: 3000,
                intent: 'success'
            })
            this.props.actions.getStudentAppliedCourses(params.id)
        };
        this.props.actions.updateAppliction(id, data, handleSuccess, handleFailure)
        this.props.actions.createActivity(user_activity, () =>{}, () =>{})
        if(ZAstudentId){
            const za_activity = {
                'za_student_id': ZAstudentId,
                'application_status': currentStatus,
                'university_name': universityName,
                'course_name': appliedCourses,
                'course_level': courseLevel
            }
            this.props.actions.za_appliaction_activity(za_activity, () =>{}, () =>{})
        }
    }


    updatAssignedStatus = (id) => {

        const { match: { params }, student, userProfile: { organization, user } } = this.props;
        let ZAstudentId = student.za_id ? student.za_id : null

        const data = {
            "assigned_id": id,
            'organization_id': organization.organization_id,
            'assigned_from': user.id
        }
        const zadata = {
            "zilter_counsellor_id": id,
            'user_id': ZAstudentId

        }
        const handleFailure = (body) => {
            this.setState({
                loginErrorMessage: Toast.show({
                    message: "Assigned Update Failed Please try again",
                    timeout: 4000,
                    intent: 'danger'
                })
            })
        }
        const handleSuccess = () => {
            const pageIndex = 1
            Toast.show({
                message: "Assigned updated successfully !!!!",
                timeout: 3000,
                intent: 'success'
            })
        };
        let studentId = params.id
        this.props.actions.updateAssigned(studentId, data, handleSuccess, handleFailure)
        if (ZAstudentId) {
            this.props.actions.ZAupdateAssigned(ZAstudentId, zadata, () => { }, () => { })
        }
    }
    updatsupportStaffStatus= (id) =>{
        const { match: { params }, student, userProfile: { organization, user } } = this.props;
        // let ZAstudentId = student.za_id ? student.za_id : null
        const data = {
            "support_staff_id": id,
            'organization_id': organization.organization_id,
            'assigned_from': user.id
        }
        
        const handleFailure = (body) => {
            this.setState({
                loginErrorMessage: Toast.show({
                    message: "Assigned Update Failed Please try again",
                    timeout: 4000,
                    intent: 'danger'
                })
            })
        }
        const handleSuccess = () => {
            const pageIndex = 1
            Toast.show({
                message: "Assigned updated successfully !!!!",
                timeout: 3000,
                intent: 'success'
            })
            //    this.props.actions.getStudentAppliedCourses(params.id)
        };
        let studentId = params.id
        this.props.actions.updateStudentStaff(studentId, data, handleSuccess, handleFailure)
        // const zadata = {
        //     "zilter_counsellor_id": id,
        //     'user_id': ZAstudentId

        // }
        // if (ZAstudentId) {
        //     this.props.actions.ZAupdateAssigned(ZAstudentId, zadata, () => { }, () => { })
        // }

    }
    updatleadStatus = (status) => {
        const { match: { params }, userProfile: { user, organization } } = this.props
        const data = {
            "lead_status": status,
            "id": params.id,
            "organization_id": organization.organization_id,
            "u_id":user.id
        }
        const handleFailure = (body) => {
            this.setState({
                loginErrorMessage: Toast.show({
                    message: "Lead status Update Failed Please try again",
                    timeout: 4000,
                    intent: 'danger'
                })
            })
        }
        const handleSuccess = () => {
            const pageIndex = 1
            Toast.show({
                message: "Lead status updated successfully !!!!",
                timeout: 3000,
                intent: 'success'
            })
            //    this.props.actions.getStudentAppliedCourses(params.id)
        };
        //    let studentId = params.id
        this.props.actions.updateStudent(data, handleSuccess, handleFailure)

    }
    onChange = (name, event, id, index, universityName, appliedCourses, courseLevel, applicationStatus) => {
        this.setState({ [name]: event.target.value });

        if (name == `status${index}`) {
            this.updateStatus(id, event.target.value)
        }
        if (name == `applicationValue${index}`) {
            this.updatApplictioneStatus(id, event.target.value, universityName, appliedCourses, courseLevel, applicationStatus)

        }
    }
    onChangeApplication = (name, value) => {
        this.setState({ [name]: value });

    }
    onChangeFeedback = (value,index) => {
        const {stateFeedBackForm} = this.state
        stateFeedBackForm.form_questions[index].value = value
        this.setState({ stateFeedBackForm});

    }
    onEditApplicationcoursename = (uId) => {
        this.props.actions.getZAUniversityCourses(uId)
    }
    onChangeInput = (name, value) => {
        this.setState({
            [name]: value,
        });

        if (name == "assignedValue") {
            this.updatAssignedStatus(value)
        }
        if (name == "supportStaffValue") {
            this.updatsupportStaffStatus(value)
        }
        if (name == "leadStatusValue") {
            this.updatleadStatus(value)
        }
        if (name == "applicationCourseUniversity") {
            let uId = value.value
            const validUUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(uId);
            if (validUUID) {
                this.props.actions.getZAUniversityCourses(uId)
            }
            else {
                const data = {
                    "university_name": uId,
                    "coordinates": null,
                    "twitter_name": null,
                    "alias": null,
                    "logo": null,
                    "website": "NA",
                    "no_of_students": null,
                    "about": null,
                    "employability_percentage": "NA",
                    "average_salary_per_year": "0",
                    "average_overall_cost": "0",
                    "rank_THE": "NA",
                    "rank_qs": "NA",
                    "rank_guardian": "NA",
                    "rank_ft": "NA",
                    "established_year": null,
                    "job_support": "YES",
                    "scholarship": "YES",
                    "internship": "YES"
                }

                const handleFailure = (res, body) => {

                }
                const handleSuccess = (body) => {
                    const university = { 'value': body.university.id, 'label': body.university.name }
                    this.setState({ applicationCourseUniversity: university })
                };
                // this.props.courseActions.universityCreateZoomabroad(handleSuccess, handleFailure, data)   //createUniversity
            }
        }
        if (name == 'appicationCourseCreate') {
            let cId = value.value
            const isNumeric = (num) => (typeof (num) === 'number')

            if (!isNumeric(cId)) {
                const courseData = {
                    "name": cId,
                    "views": 0,
                    "about": "zilter",
                    "courseDurationYear": 0,
                    "courseDurationMonth": 0,
                    "courseIntakeMonth": 0,
                    "tuitionFee": 0,
                    "academicRequirement": null,
                    "academicRequirementPercentage": 0,
                    "languageRequirement": 0,
                    "experienceRequirement": 0,
                    "otherRequirements": 0,
                    'alias': 'zilter',
                    'description': null,
                    'courseDuration': 0,
                    'courseDurationType': 1,
                    'courseFees': 0,
                    'englishSpeaking': 1,
                    'languageScore': 0,
                    'ieltsScore': 0,
                    'toeflScore': 0,
                    'courseAdmissions': 0,
                    'totalRating': 0,
                    'status': 0,
                }
                //   courseCreateZoomabroad
                const handleFailure = (res, body) => {

                }
                const handleSuccess = (body) => {
                    const course = { 'value': body.course.id, 'label': body.course.name }
                    this.setState({ appicationCourseCreate: course })

                    const coursesData = {
                        "course_id": body.course.id,
                        "course": cId,
                        "course_type": 1,
                        "domestic_fee_usd": 0,
                        "international_fee_usd": 0,
                        "module_id": 0,
                        "duration": 0,
                        "start_month": 0,
                        "language_id": 0,
                        "course_short_name": 0,
                        "maths12th": 0,
                        "english12th": 0,
                        "science12th": 0,
                        "foundation": 0,
                        "experience_require": 0
                    }
                    const uniCourseData = [{
                        'university_id': this.state.applicationCourseUniversity.value,
                        'course_id': body.course.id,
                        'course_fee': 0,
                        'university_campuses': '',
                        'course_intake': '',
                        'status': 0

                    }]
                    // this.props.courseActions.coursesCreateZoomabroad(() => { }, () => { }, coursesData)   //createUniversity

                    // this.props.courseActions.uniCourseCreateZoomabroad(() => { }, () => { }, uniCourseData)   //createUniversity


                };
                // this.props.courseActions.courseCreateZoomabroad(handleSuccess, handleFailure, courseData)   //createUniversity


                //   university_id, course_id, course_fee, university_campuses
                //                 , course_intake
                //                 , status
            }
        }
    }

    onSave = () => {
        const { inputs } = this.state;
        const request = {

        }
        const handleFailure = (res, body) => {
            this.setState({
                loginErrorMessage: Toast.show({
                    message: "Update Failed Please try again",
                    timeout: 4000,
                    intent: 'danger'
                })
            })
        }
        const handleSuccess = () => {
            this.props.history.push(`/profile/view/${inputs.id}`);
            Toast.show({
                message: "Profile updated successfully !!!!",
                timeout: 3000,
                intent: 'success'
            })
        };
        // this.props.actions.update(handleSuccess, handleFailure, request);

    }

    onMessage = () => {
        this.setState({ onShowMessage: true });
    }

    onChatMessage = () => {
        this.setState({ onShowChatMessage: true });

    }

    onNote = (logsDetail, action) => {
        this.setState({ onShowNote: true });
        if (action == 'edit') {
            let note = logsDetail.note
            let noteState = note.replace(/(<([^>]+)>)/ig, '')
            this.setState({
                subject: logsDetail.subject,
                editId: logsDetail.id,
                editState: true,
                editorState: EditorState.createWithContent(ContentState.createFromText(noteState)),
                noteStatusValue: logsDetail.note_status
            })
        }
    }

    onTask = (logsDetail, action) => {
        this.setState({ onShowTask: true });
        if (action == 'edit') {
            let note = logsDetail.note
            let noteState = note.replace(/(<([^>]+)>)/ig, '')
            this.setState({
                subject: logsDetail.subject,
                editId: logsDetail.id,
                editState: true,
                editorState: EditorState.createWithContent(ContentState.createFromText(noteState))
            })
        }
    }
    onWhatsappEdit = (logsDetail, action) => {
        this.setState({ onShowWhatsapp: true });
        if (action == 'edit') {
            let note = logsDetail.note
            let noteState = note.replace(/(<([^>]+)>)/ig, '')
            this.setState({
                subject: logsDetail.subject,
                editId: logsDetail.id,
                editState: true,
                editorWhatsState: EditorState.createWithContent(ContentState.createFromText(noteState))
            })
        }
    }
    onStatus = () => {
        this.setState({
            onShowStatus: true
        })
    }

    onWhatsapp = () => {
        this.setState({
            onShowWhatsapp: true
        })
    }
    onCloseModal = () => {
        this.props.actions.applicationModelOpen()
        this.setState({
            onShowMessage: false,
            onShowNote: false,
            onShowTask: false,
            onShowStatus: false,
            editState: false,
            editorState: EditorState.createEmpty(),
            subject: "",
            editId: "",
            duedate: new Date(),
            editorNoteState: EditorState.createEmpty(),
            showApplicationCreateModal: '',
            appicationCourseCreate: '',
            applicationCourseType: '',
            applicationCourseUniversity: '',
            applicationIntakeMonth: '',
            applicationIntakeYear: '',
            applicationChannelName: '',
            showDocModal: false,
            modalDocUrl: '',
            onShowWhatsapp: false,
            editorWhatsState: EditorState.createEmpty(),
            showApplicationStatusModal: false,
            clearModel: false,
            showApplicationNumberModal: false,
            applicationNumber: '',
            showApplicationIntakeModal: false,
            showApplicationcourseModal: false,
            onShowChatMessage: false,
            showAllocateModal: false
        });
    }
    onClearAppModal = () => {

        this.setState({
            appicationCourseCreate: '',
            applicationCourseType: '',
            applicationCourseUniversity: '',
            applicationIntakeYear: '',
            applicationIntakeMonth: '',
            applicationChannelName: '',

        });
    }

    itemRender = (current, type, element) => {
        if (type === 'page') {

            return <a>{current}</a>;
        }
        return element;
    };

    onPageChange = (pageIndex) => {
        const { match: { params } } = this.props;
        this.setState({
            pageIndex: pageIndex
        });
        this.props.actions.getLogs(params.id, pageIndex)
    }
    openInNewTab = (id) => {
        // this.props.history.push(`/student/edit/${id}`, '_blank');
        let url = `${URL}/student/edit/${id}`
        let win = window.open(url, '_blank');
        win.focus();
    }

    onStudentList = () => {
        this.props.history.push(`/students`)
    }

    onSubjectChange = (name, value) => {
        this.setState({
            [name]: value,
        });
    };

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    onEditState = () => {
        this.setState({
            editState: false,
            editorState: EditorState.createEmpty(),
            subject: "",
            editId: "",
            noteStatusValue: "",
            editorNoteState: EditorState.createEmpty(),
            editorWhatsState: EditorState.createEmpty()
        });
    }

    onDeleteStudentLogs = (id) => {
        const { match: { params } } = this.props;
        this.props.actions.getLogs(params.id, 1)
        const handleSuccess = () => {
            Toast.show({
                message: "deleted successfully !!!!",
                timeout: 3000,
                intent: 'success'
            })
        };
        this.props.actions.deleteStudentLogs(id, handleSuccess, () => { })
    }
    onEditNoteChange = (editorNoteState) => {
        this.setState({
            editorNoteState
        })
    }
    oneditorWhatsChange = (editorWhatsState) => {
        this.setState({
            editorWhatsState
        })
    }
    onApplyCourse = () => {
        this.setState({ showApplicationCreateModal: true })
    }
    onApplyCreateCourse = () => {
        const { match: { params }, userProfile: { organization }, studentZA } = this.props;
        const { appicationCourseCreate, applicationCourseType, applicationCourseUniversity, applicationIntakeMonth, applicationIntakeYear, applicationChannelName } = this.state
        const handleSuccess = () => {
            this.props.actions.getStudentAppliedCourses(params.id)
            Toast.show({
                message: "Apply course successfully !!!!",
                timeout: 3000,
                intent: 'success'
            })
            this.onCloseModal()
        }
        const handleFailure = () => {
            Toast.show({
                message: "Apply course unsuccessfull !!!!",
                timeout: 3000,
                intent: 'danger'
            })
        }
        const zilterData = {
            s_id: params.id,
            applied_courses: appicationCourseCreate ? appicationCourseCreate.label : '',
            course_level: applicationCourseType ? applicationCourseType.value : '',
            university_name: applicationCourseUniversity ? applicationCourseUniversity.label : '',
            application_status_id: "5e599523c1664d728cb41602d8b38e7e",
            application_intake_year: applicationIntakeYear ? applicationIntakeYear.value : '',
            application_intake_month: applicationIntakeMonth ? applicationIntakeMonth.value : '',
            channel: applicationChannelName ? applicationChannelName.value : '',
            selcected_by: 'advisor',
            offer: null
        }
        this.props.actions.studentCourseApply(handleSuccess, handleFailure, zilterData);
        if (studentZA && studentZA.id) {
            const zoomAbroadData = {

                user_id: studentZA.id ? studentZA.id : '',
                course_id: appicationCourseCreate ? appicationCourseCreate.value : '',
                university_id: applicationCourseUniversity ? applicationCourseUniversity.value : '',
                comment: 'listing'

            }
            this.props.courseActions.applyNowZoomabroad(() => { }, () => { }, zoomAbroadData);
        }


    }
    showDocumentModal = (doc) => {
        this.setState({
            showDocModal: true,
            modalDocUrl: doc
        })
    }
    onApplicationStatusModal = (acd) => {
        const array = [
            'Offer_Letter_Conditional',
            'University Assessment / Interview',
            'Unconditional Offer Letter',
            'Fee Deposit',
            'Visa Appointment Book',
            'Visa Applied',
            'Visa Granted',
            'Visa Rejected',
            'Balance Fee Payment',
            'Enrolled In University',
            'Invoice',
            'Lost',
            'CAS Received',
            'CAS Applied',
            'Application Rejected'
        ]
        const ind = array.indexOf(acd.application_status)

        this.setState({
            showApplicationStatusModal: true,
            applicationStatusIndex: ind == '-1' ? 15 : ind,
            applicationModalName: acd.application_status,
            applicationStatusId: acd.id
        })
        switch (ind) {
            case 0:
                this.props.actions.getAppConditionalOffer(acd.id)
                break
            case 1:
                this.props.actions.getAppUniAssessment(acd.id)
                break
            case 2:
                this.props.actions.getAppUnConditionalOffer(acd.id)
                break
            case 3:
                this.props.actions.getAppFeedeposit(acd.id)
                break
            case 4:
                this.props.actions.getAppVisaAppointment(acd.id)
                break
            case 5:
                this.props.actions.getAppVisaApplied(acd.id)
                break
            case 6:
                this.props.actions.getAppVisaGranted(acd.id)
                break
            case 7:
                this.props.actions.getAppVisaRefused(acd.id)
                break
            case 8:
                this.props.actions.getBalanceFeePayment(acd.id)
                break
            case 9:
                this.props.actions.getAppEnrolled(acd.id)
                break
            case 10:
                this.props.actions.getAppInvoice(acd.id)
                break
            case 11:
                this.props.actions.getAppLost(acd.id)
                break
            case 12:
                this.props.actions.getAppCasReceived(acd.id)
                break
            case 13:
                this.props.actions.getAppCasApplied(acd.id)
                break
            case 14:
                this.props.actions.getAppRejected(acd.id)
                break
            default:
                console.log("i is greater than 2.");
        }

    }
    setClearForm = () => {
        this.setState(
            { clearModel: true }
        )
    }
    openApplicarionEditModal = (application) => {
        this.setState(
            {
                showApplicationNumberModal: true,
                applicationEdit: application,
                applicationNumber: application.application_number
            }
        )
    }
    openApplicarionIntakeEditModal = (application) => {
        this.setState(
            {
                showApplicationIntakeModal: true,
                applicationEdit: application,
                application_intake_month: application.application_intake_month,
                application_intake_year: application.application_intake_year
            }
        )
    }
    openApplicarionCourseEditModal = (application) => {
        this.setState(
            {
                showApplicationcourseModal: true,
                applicationEdit: application,
                applied_courses: application.applied_courses
            }
        )
    }

    onApplicationEditModal = () => {
        const { match: { params } } = this.props;
        const { applicationNumber, applicationEdit } = this.state

        const data = {
            'application_number': applicationNumber
        }

        const handleFailure = (body) => {
            this.setState({
                loginErrorMessage: Toast.show({
                    message: "Failed Please try again",
                    timeout: 4000,
                    intent: 'danger'
                })
            })
            this.onCloseModal()
        }

        const handleSuccess = () => {
            const pageIndex = 1
            Toast.show({
                message: "Save successfully !!!!",
                timeout: 3000,
                intent: 'success'
            })
            this.props.actions.getStudentAppliedCourses(params.id)
            this.onCloseModal()
        };

        this.props.actions.updateAppliction(applicationEdit.id, data, handleSuccess, handleFailure)
    }
    
    onIntakeEditModal = () => {
        const { match: { params } } = this.props;
        const { application_intake_month,application_intake_year, applicationEdit } = this.state

        const data = {
            'application_intake_month': application_intake_month,
            'application_intake_year': application_intake_year
        }
        const handleFailure = (body) => {
            this.setState({
                loginErrorMessage: Toast.show({
                    message: "Failed Please try again",
                    timeout: 4000,
                    intent: 'danger'
                })
            })
            this.onCloseModal()
        }

        const handleSuccess = () => {
            const pageIndex = 1
            Toast.show({
                message: "Save successfully !!!!",
                timeout: 3000,
                intent: 'success'
            })
            this.props.actions.getStudentAppliedCourses(params.id);            
            this.props.actions.getStudentApplicationIntake(params.id);
            this.onCloseModal()
        };
        this.props.actions.updateAppliction(applicationEdit.id, data, handleSuccess, handleFailure)
    }
    onCourseEditModal = () => {
        const { match: { params } } = this.props;
        const { application_intake_month,application_intake_year, applicationEdit,applied_courses } = this.state

        const data = {
            'applied_courses': applied_courses
        }
        const handleFailure = (body) => {
            this.setState({
                loginErrorMessage: Toast.show({
                    message: "Failed Please try again",
                    timeout: 4000,
                    intent: 'danger'
                })
            })
            this.onCloseModal()
        }

        const handleSuccess = () => {
            const pageIndex = 1
            Toast.show({
                message: "Save successfully !!!!",
                timeout: 3000,
                intent: 'success'
            })
            this.props.actions.getStudentAppliedCourses(params.id)
            this.onCloseModal()
        };

        this.props.actions.updateAppliction(applicationEdit.id, data, handleSuccess, handleFailure)
    }
    taskAllocateModel = () => {
        this.setState({
            showTaskAllocateModal: true
        })
    }
    onCloseTaskAllocateModal = () => {
        this.setState({
            showTaskAllocateModal: false,
            showAllocateModal: true
        })
    }
    onTaskIndex = (taskIndex, taskName) => {
        this.setState({
            taskIndex: taskIndex,
            taskName: taskName
        })
    }
    openFeedbackModal=()=>{
        this.setState({
            showFeedbackform:true
        })
    }
    closefeedbackModal=()=>{
        this.setState({
            showFeedbackform:false
        })
    }

    onSubmitFeedback = () =>{
        const {attendMeeting, match: { params }, userProfile:{user}} = this.props
        const { stateFeedBackForm } = this.state
        const data = {
            
                "user_id":user.id,
                "student_id":params.id,
                "meeting_time":attendMeeting.meeting_time,
                "meeting_link":attendMeeting.meeting_link,
                "meeting_subject":attendMeeting.meeting_subject,
                "meeting_description":attendMeeting.meeting_description,
                "meeting_feedback":stateFeedBackForm
        
        
        }
        const handleSuccess=()=>{
            Toast.show({
                message: "Submitted successfully !!!!",
                timeout: 3000,
                intent: 'success'
            })

                this.setState({ stateFeedBackForm:{}});
        
            
            this.closefeedbackModal()
        }
        const handleError=()=>{
            Toast.show({
                message: "Failed Please try again",
                timeout: 4000,
                intent: 'danger'
            })
        }

        this.props.actions.consellorMeeting(data,handleSuccess, handleError)
    }
    render() {
        const { modalDocUrl, inputs, onShowMessage, onShowNote, fromEmail, toEmail, onShowTask,
            pageIndex, status, onShowStatus, assignedValue, applicationValue, subject, courseName,
            editorState, editState, editId, leadStatusValue, noteStatusValue, editorNoteState,
            noteUseValue, showApplicationCreateModal, appicationCourseCreate, applicationCourseType,
            applicationCourseUniversity, showDocModal, applicationChannelName, applicationIntakeMonth,
            applicationIntakeYear, onShowWhatsapp, phoneNumber, editorWhatsState, showApplicationStatusModal,
            applicationStatusIndex, applicationModalName, applicationStatusId, clearModel, showApplicationNumberModal,application_intake_month,application_intake_year,
            applicationNumber, onShowChatMessage, showTaskAllocateModal, taskIndex, showAllocateModal, taskName,showApplicationIntakeModal, supportStaffValue,showFeedbackform,stateFeedBackForm ,showApplicationcourseModal, applied_courses} = this.state
        const { student, isLoading, userProfile, logsDetails, studentCourses, applicationStatus, members,
            zoomabroadCourses, zoomabroadUniversity, channelList, sourceList, attendMeeting,checkList,getFeedbackForm, taskVisaAppointment,studentAppicationIntake } = this.props
        return (

            <StudentViewMarkup
                showDocumentModal={this.showDocumentModal}
                showDocModal={showDocModal}
                modalDocUrl={modalDocUrl}
                inputs={inputs}
                isLoading={isLoading}
                onChange={this.onChange}
                onSave={this.onSave}
                student={student}
                onMessage={this.onMessage}
                onShowMessage={onShowMessage}
                onNote={this.onNote}
                onShowNote={onShowNote}
                pageIndex={pageIndex}
                onShowTask={onShowTask}
                onCloseModal={this.onCloseModal}
                onClearAppModal={this.onClearAppModal}
                userProfile={userProfile}
                fromEmail={fromEmail}
                toEmail={toEmail}
                logsDetails={logsDetails}
                onTask={this.onTask}
                itemRender={this.itemRender}
                onPageChange={this.onPageChange}
                onChangetext={this.onChange}
                status={status}
                myState={this.state}
                studentCourses={studentCourses}
                applicationStatus={applicationStatus}
                onShowStatus={onShowStatus}
                onStatus={this.onStatus}
                members={members}
                onChangeInput={this.onChangeInput}
                assignedValue={assignedValue}
                applicationValue={applicationValue}
                openInNewTab={this.openInNewTab}
                subject={subject}
                onSubjectChange={this.onSubjectChange}
                courseName={courseName}
                editorState={editorState}
                onEditorStateChange={this.onEditorStateChange}
                editState={editState}
                editId={editId}
                onEditState={this.onEditState}
                onDeleteStudentLogs={this.onDeleteStudentLogs}
                leadStatusValue={leadStatusValue}
                noteStatusValue={noteStatusValue}
                leadState={this.state}
                editorNoteState={editorNoteState}
                onEditNoteChange={this.onEditNoteChange}
                applyZoomabroad={this.applyZoomabroad}
                onStudentList={this.onStudentList}
                noteUseValue={noteUseValue}
                showApplicationCreateModal={showApplicationCreateModal}
                onApplyCourse={this.onApplyCourse}
                onApplyCreateCourse={this.onApplyCreateCourse}
                zoomabroadCourses={zoomabroadCourses ? zoomabroadCourses : []}
                zoomabroadUniversity={zoomabroadUniversity ? zoomabroadUniversity : []}
                channelList={channelList ? channelList : []}
                applicationCourseType={applicationCourseType}
                applicationCourseUniversity={applicationCourseUniversity}
                appicationCourseCreate={appicationCourseCreate}
                sourceList={sourceList}
                applicationChannelName={applicationChannelName}
                applicationIntakeYear={applicationIntakeYear}
                applicationIntakeMonth={applicationIntakeMonth}
                onShowWhatsapp={onShowWhatsapp}
                onWhatsapp={this.onWhatsapp}
                phoneNumber={phoneNumber}
                editorWhatsState={editorWhatsState}
                oneditorWhatsChange={this.oneditorWhatsChange}
                onWhatsappEdit={this.onWhatsappEdit}
                showApplicationStatusModal={showApplicationStatusModal}
                onApplicationStatusModal={this.onApplicationStatusModal}
                applicationStatusIndex={applicationStatusIndex}
                applicationModalName={applicationModalName}
                applicationStatusId={applicationStatusId}
                clearModel={clearModel}
                setClearForm={this.setClearForm}
                openApplicarionEditModal={this.openApplicarionEditModal}
                showApplicationNumberModal={showApplicationNumberModal}
                onApplicationEditModal={this.onApplicationEditModal}
                onChangeApplication={this.onChangeApplication}
                applicationNumber={applicationNumber}
                onShowChatMessage={onShowChatMessage}
                onChatMessage={this.onChatMessage}
                taskAllocateModel={this.taskAllocateModel}
                showTaskAllocateModal={showTaskAllocateModal}
                onCloseTaskAllocateModal={this.onCloseTaskAllocateModal}
                taskIndex={taskIndex}
                onTaskIndex={this.onTaskIndex}
                showAllocateModal={showAllocateModal}
                attendMeeting={attendMeeting}
                taskName={taskName}
                checkList={checkList}
                supportStaffValue={supportStaffValue}
                mySupportState={this.state}
                showFeedbackform={showFeedbackform}
                openFeedbackModal={this.openFeedbackModal}
                closefeedbackModal={this.closefeedbackModal}
                getFeedbackForm={stateFeedBackForm}
                onChangeFeedback={this.onChangeFeedback}
                onSubmitFeedback={this.onSubmitFeedback}
                taskVisaAppointment={taskVisaAppointment}
                onIntakeEditModal={this.onIntakeEditModal}
                application_intake_month={application_intake_month}
                application_intake_year={application_intake_year}
                applied_courses={applied_courses}
                showApplicationIntakeModal={showApplicationIntakeModal}
                openApplicarionIntakeEditModal={this.openApplicarionIntakeEditModal}
                onEditApplicationcoursename={this.onEditApplicationcoursename}
                showApplicationcourseModal={showApplicationcourseModal}
                openApplicarionCourseEditModal={this.openApplicarionCourseEditModal}
                onCourseEditModal={this.onCourseEditModal}
                studentAppicationIntake={studentAppicationIntake}
            />
        );
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        student: state.students.student,
        logsDetails: state.students.logsDetails,
        userProfile: state.Auth.user,
        studentCourses: state.students.studentCourses,
        applicationStatus: state.students.applicationStatus,
        members: state.members.Members,
        zoomabroadCourses: state.students.zoomabroadCourses,
        zoomabroadUniversity: state.students.zoomabroadUniversity,
        channelList: state.channel.channelList,
        studentZA: state.students.studentZA,
        sourceList: state.source.sourceList,
        checkList: state.students.checkList,
        getFeedbackForm: state.students.getFeedbackForm,
        attendMeeting: state.students.attendMeeting,
        taskVisaAppointment: state.students.taskVisaAppointment,
        studentAppicationIntake: state.students.studentAppicationIntake
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
        memberActions: bindActionCreators(memberActions, dispatch),
        channelAction: bindActionCreators(channelAction, dispatch),
        courseActions: bindActionCreators(courseActions, dispatch),
        sourceActions: bindActionCreators(sourceActions, dispatch)
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(StudentView));