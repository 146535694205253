import React, { Component } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Toast } from '../../../../shared'
import DocumentList from '../../modules/document.json'
import actions from '../../modules/actions'
import { ORG_ID } from '../../../../env'
import moment from 'moment';
import {
    Label
} from "@blueprintjs/core";
import './RequiredDocuments.scss';

class RequiredDocuments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            birthCertificate: false,
            sscMarksheet: false,
            hscMarksheet: false,
            bachelorMarksheet: false,
            masterMarksheet: false,
            diplomaMarksheet: false,
            passport: false,
            resume: false,
            experiance: false,
            SOP: false,
            LOR: false,
            languageCertificate: false,
            others: false,
            id: '',
            errors: {}
        }
    }
    componentDidMount() {
        const { match: { params }, userProfile, student } = this.props;
        this.props.actions.getStudent(params.id);
    }

    static getDerivedStateFromProps(props, state) {

    }

    isvalid = () => {
        const { description, subject } = this.state;
        let errors = {};

        if (description == '') {
            errors.description = true;
            this.setState({ errors });
            return false;
        }
        if (subject == '') {
            errors.subject = true;
            this.setState({ errors });
            return false;
        }


        return true;
    };

    onSubmit = () => {
        const { match: { params }, userProfile, student, onCloseModal } = this.props;
        const { birthCertificate,
            sscMarksheet,
            hscMarksheet,
            bachelorMarksheet,
            masterMarksheet,
            diplomaMarksheet,
            passport,
            resume,
            experiance,
            SOP,
            LOR,
            languageCertificate,
            others,
        } = this.state;

        if (this.isvalid()) {

            const taskList = {
                'student_id': params.id,
                'user_id': userProfile.organization.user_id,
                'organization_id': userProfile.organization.organization_id,
                'meeting_schedule': false,
                'attending_meeting': false,
                'required_documents': true,
                'visa_appointment': false,
                'opportunity': false,
                'select_offer': false,
                'qualifying': false,

            }

            const data = {
                'student_id': params.id,
                'user_id': userProfile.organization.user_id,
                'organization_id': userProfile.organization.organization_id,
                "document_list": JSON.stringify(
                    [{
                        birth_Certificate: birthCertificate,
                        sscMarksheet: sscMarksheet,
                        hscMarksheet: hscMarksheet,
                        bachelorMarksheet: bachelorMarksheet,
                        masterMarksheet: masterMarksheet,
                        diplomaMarksheet: diplomaMarksheet,
                        passport: passport,
                        resume: resume,
                        experiance: experiance,
                        SOP: SOP,
                        LOR: LOR,
                        languageCertificate: languageCertificate,
                        others: others,
                    }]
                ),

            }
            let documentList =
                [sscMarksheet ? 'SSC Marksheet' : '',
                hscMarksheet ? 'HSC Marksheet' : '',
                bachelorMarksheet ? 'Bachelor Marksheet' : '',
                masterMarksheet ? 'Master Marksheet' : '',
                diplomaMarksheet ? 'Diploma Marksheet' : '',
                passport ? 'Passport' : '',
                resume ? 'Resume / CV' : '',
                sscMarksheet ? 'SSC Marksheet' : '',
                experiance ? 'Experiance' : '',
                languageCertificate ? 'Language Certificate' : '',
                SOP ? 'SOP' : '',
                LOR ? 'LOR' : '',
                others ? 'others' : ''
                ]

            const log = {
                "std": params.id,           ////student_id
                "u_id": userProfile ? userProfile.organization.user_id : null,
                "student_courses_id": null,
                "subject": 'Task assigned required documents',
                "type": 'note',
                "note": documentList.toString(),
                "note_status": userProfile.organization.organization_id == ORG_ID ? 'private' : 'private',
                "student_name": student.first_name,
                "student_email": student.email,
                "organization_id": userProfile ? userProfile.organization.organization_id : null,

            }
            const ZAtaskList = {
                'user_id': student ? student.za_id : null,
                'meeting_schedule': false,
                'attending_meeting': false,
                'required_documents': true,
                'visa_appointment': false,
                'opportunity': false,
                'select_offer': false,
                'qualifying': false,

            }
            const zadata = {
                'user_id': student ? student.za_id : null,
                "document_list": JSON.stringify(
                    [{
                        birth_Certificate: birthCertificate,
                        sscMarksheet: sscMarksheet,
                        hscMarksheet: hscMarksheet,
                        bachelorMarksheet: bachelorMarksheet,
                        masterMarksheet: masterMarksheet,
                        diplomaMarksheet: diplomaMarksheet,
                        passport: passport,
                        resume: resume,
                        experiance: experiance,
                        SOP: SOP,
                        LOR: LOR,
                        languageCertificate: languageCertificate,
                        others: others,
                    }]
                ),

            }
            const handlesuccessfull = () => {
                Toast.show({
                    message: "Task allocate successfully!",
                    timeout: 4000,
                    intent: 'success'
                })
                this.setState({
                    description: '',
                    subject: '',
                    id: ''
                })

                onCloseModal()

            }
            const handleFailure = () => {
                Toast.show({
                    message: "Task allocate unsuccessfull!",
                    timeout: 4000,
                    intent: 'danger'
                })
                this.setState({
                    description: '',
                    subject: '',
                    id: ''
                })

                onCloseModal()

            }
            const handlesNoteuccessfull = () => {
                const page = 1
                this.props.actions.getLogs(student.id, page)
            }

            this.props.actions.taskAllocation(taskList, () => { }, () => { })
            this.props.actions.counsellorRequiredDoc(data, handlesuccessfull, handleFailure)

            this.props.actions.createNoteLog(log, handlesNoteuccessfull, () => { });

            if (ZAtaskList.user_id) {
                this.props.actions.ZAtaskAllocation(ZAtaskList, () => { }, () => { })
                this.props.actions.ZAcounsellorRequiredDoc(zadata, handlesuccessfull, handleFailure)
            }
        }
    }
    onChangeInput = (name, value) => {
        this.setState({
            [name]: value, errors: {}
        })

    }
    render() {
        const { birthCertificate, graduationCertificate, ieltsCertificate } = this.state;
        const { } = this.props

        return (
            <div className="balance-fee">
                {DocumentList && DocumentList.length ? DocumentList.map(docName => {
                    return (
                        <div>
                            <Form.Group >
                                <Form.Check
                                    name={[docName.value]}
                                    type="checkbox"
                                    label={docName.label}
                                    className="check-box"
                                    value={[docName.value]}
                                    // checked={[docName.value] ? true : false}
                                    onChange={(event) => this.onChangeInput(event.target.name, event.target.checked)}

                                />
                            </Form.Group>
                        </div>
                    )
                })
                    : ''}

                <Col className="button-save">
                    <Button className="save-offer" onClick={() => this.onSubmit()}>Allocate</Button>
                </Col>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        student: state.students.student,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RequiredDocuments));
